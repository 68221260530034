import Abi from "./Abi.json";
// import Erc20Abi from './Erc20abi.json';
import TokenAbiData from "./TokenAbi.json";
import InfoAbiData from "./InfoAbi.json";
import PresaleAbiData from "./PresaleAbi.json";
import TokenContractABI from "./TokenContractABI.json";
import LiqValueCalcABI from "./LiqValueCalcABI.json";
// import FactoryBinanceABI from './FactoryBinanceABI.json';
// import InfoBinanceABI from './InfoBinanceABI.json';

export const PresaleAbi = PresaleAbiData;

export const TokenContractABIData = TokenContractABI;

export const InfoAbi = InfoAbiData;
// export const FactoryBinanceABIData = FactoryBinanceABI;
// export const InfoBinanceABIData = InfoBinanceABI;
export const TokenAbi = TokenAbiData;

export const AbiDataETH = Abi;
export const LiqValueCalcABIData = LiqValueCalcABI;

// export const Erc20AbiData = Erc20Abi;

export const factoryContractAddressETH =
  "0x338C5406B58dbF531f3dC57Af222De8f4A8EA973";

export const FactoryContractAddressBNB =
  "0xcF1bf502C2A8bfB93b9A715dAEb4CaBd85863BC8";

export const FactoryContractAddressMatic =
  "0x57594D7921dcbe64cc299E6dD99D5f7F72f7478F";

export const mulltiCallAddressEth =
  "0x23206C75D6D11497C2ACD2eb508d94CD0f05c8e9";
export const mulltiCallAddressBnb =
  "0x32F8647b2ff89B454170A25037D7643d219d6A19";
export const mulltiCallAddressMatic =
  "0x19E52c4E33dEDf1121165528712D9916f646b1eB";

export const infoContractBnb = "0xf9632bf7e3062879B21ca37b2D32C81e6b0589E9";
export const infoContractEth = "0x2E0fF7e2B708F55dbDf587CB102D653Ff4CBCb3D";
export const infoContractMatic = "0x16715558e4712764cECd5238FA669a7B81D303fe";

// export const presaleContractAddress =
//   '0xbcCD5aC28dDda42548680754a38549643ea69430';

// export const InfoContractAddress = '0x6518B027974099B9B164f59dd58BC4fe29601276';

// export const SafuInfoContractAddress =
//   '0x38F9a5e26f554Ce7a2fA43432C73DF207123be3F';

export function isUrlValid(userInput) {
  var res = userInput.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g // eslint-disable-line no-useless-escape
  );
  if (res == null) return false;
  else return true;
}

export function isUrlValidTelegram(userInput) {
  var res = userInput.match(
    /https?:\/\/(t(elegram)?\.me|telegram\.org)\/([a-z0-9\_]{5,32})\/?/g // eslint-disable-line no-useless-escape
  );
  if (res == null) return false;
  else return true;
}
