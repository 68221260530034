import { Interface } from '@ethersproject/abi';
import MultiCallAbi from 'src/context/Utils/Multicall.json';
import {
  mulltiCallAddressEth,
  mulltiCallAddressMatic,
  mulltiCallAddressBnb,
} from 'src/context/Utils';
import { getContract } from 'src/operations';

const multicall = async (abi, calls) => {
  const multicallAdd =
    window.localStorage.getItem('network') === 'Binance Chain'
      ? mulltiCallAddressBnb
      : window.localStorage.getItem('network') === 'Matic'
      ? mulltiCallAddressMatic
      : mulltiCallAddressEth;

  const multi = getContract(MultiCallAbi, multicallAdd);
  const itf = new Interface(abi);

  const calldata = calls.map((call) => [
    call.address.toLowerCase(),
    itf.encodeFunctionData(call.name, call.params),
  ]);
  const { returnData } = await multi.methods.aggregate(calldata).call();
  const res = returnData.map((call, i) =>
    itf.decodeFunctionResult(calls[i].name, call)
  );
  return res;
};

export default multicall;
