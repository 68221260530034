import React, { createContext, useState, useEffect } from "react";
// import Web3 from 'web3';
import { ethers } from "ethers";
import {
  infoContractBnb,
  infoContractEth,
  infoContractMatic,
} from "src/context/Utils";
// import DscvrFactoryABI from "./Utils/DscvrFactoryABI.json";
import DscvrInfoABI from "./Utils/DscvrInfoABI.json";
import DscvrPresaleABI from "./Utils/DscvrPresaleABI.json";
import { useWeb3React } from "@web3-react/core";
import { getContract } from "src/operations";
import multicall from "src/context/Utils/multicall";
// import { useHistory } from 'react-router';
export const AuthContext = createContext();
export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(false);
  // const history = useHistory();
  const { activate, account, chainId } = useWeb3React();
  const [networkId, setNetworkId] = useState(
    window.localStorage.getItem("networkId")
      ? window.localStorage.getItem("networkId")
      : null
  );

  const [network, setNetwork] = useState(
    window.localStorage.getItem("network") === "Binance Chain"
      ? "Binance Chain"
      : window.localStorage.getItem("network") === "Matic"
      ? "Matic"
      : "Ethereum"
  );

  useEffect(() => {
    if (account && typeof account !== "undefined") {
      window.localStorage.setItem("account", account);
      setIsLogin(true);

      setUserData({
        ...userData,
        myAccount: account,
      });
    } else {
      setIsLogin(false);
    }
  }, [account]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (chainId && typeof chainId !== "undefined") {
      setNetworkId(chainId);

      window.localStorage.setItem(
        "network",
        parseInt(chainId) === 97
          ? "Binance Chain"
          : parseInt(chainId) === 137
          ? "Matic"
          : "Ethereum"
      );
      setNetwork(
        parseInt(chainId) === 97
          ? "Binance Chain"
          : parseInt(chainId) === 137
          ? "Matic"
          : "Ethereum"
      );
    }
  }, [chainId]); // eslint-disable-line react-hooks/exhaustive-deps

  const [poolListData, setpoolListData] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [upcommingPoolList, setUpcommingPoolList] = useState([]);
  const [featuredPoolList, setFeaturedPoolList] = useState([]);
  const [userData, setUserData] = useState({
    networkID: "",
    currentAccount: window.localStorage.getItem("account"),
    myAccount: null,
  });

  const disconnectToWallet = () => {
    window.localStorage.removeItem("account");
    setIsLogin(false);
  };

  const connectToWallet = (connector) => {
    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (connector && connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined;
    }

    console.log("connector", connector);
    activate(connector, undefined, true).catch((error) => {
      console.log("error", error);
      if (error) {
        activate(connector);
      }
    });
  };

  // useEffect(() => {
  //   (async function () {
  //     if (window.ethereum) {
  //       const web3 = (window.web3 = new Web3(window.ethereum));
  //       const networkId = await web3.eth.net.getId();
  //       setNetworkId(networkId);
  //     }
  //   })();
  // }, []);

  // //NETWORK CHANGE
  // useMemo(() => {
  //   if (window.ethereum) {
  //     window.ethereum.on('networkChanged', async function (networkId) {
  //       window.location.reload();
  //     });
  //   }
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // //ACCOUNT CHANGE
  // useMemo(
  //   () =>
  //     window.ethereum &&
  //     window.ethereum.on('accountsChanged', async function (accounts) {
  //       if (accounts.length > 0) {
  //         await connectToWallet();
  //       } else {
  //         window.localStorage.removeItem('account');
  //         window.localStorage.removeItem('network');
  //         window.localStorage.removeItem('networkId');
  //         alert('Please Connect to wallet');
  //         setUserData({});
  //       }
  //     }),
  //   [] // eslint-disable-line react-hooks/exhaustive-deps
  // );

  // Upcomming Pool List
  useEffect(() => {
    if (poolListData.length > 0) {
      let result = poolListData;

      let currentTimeStamp = +new Date();
      let filterResult = result.filter((data) => {
        return data.data.openTime * 1000 > currentTimeStamp;
      });
      setUpcommingPoolList(filterResult.slice(0, 6));
    }
  }, [poolListData]); // eslint-disable-line react-hooks/exhaustive-deps

  //Featured Pool List
  useEffect(() => {
    if (poolListData.length > 0) {
      let result = poolListData;
      let currentTimeStamp = +new Date();
      let filterResult = result.filter((data) => {
        return data.data.closeTime * 1000 < currentTimeStamp;
      });
      setFeaturedPoolList(filterResult.slice(0, 6));
    }
  }, [poolListData]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDSCVRAddressHandler = async () => {
    setpoolListData([]);
    const infoContractAddressLocal =
      network === "Ethereum"
        ? infoContractEth
        : network === "Matic"
        ? infoContractMatic
        : infoContractBnb;

    try {
      const contract = getContract(DscvrInfoABI, infoContractAddressLocal);
      await contract.methods
        .getPresalesCount()
        .call()
        .then(async (receipt) => {
          setIsUpdating(true);
          for (let i = receipt - 1; i >= 0; i--) {
            await getPresaleAddressHandler(infoContractAddressLocal, i);
          }
          setIsUpdating(false);
        });
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    getDSCVRAddressHandler();
  }, [isLogin]); // eslint-disable-line react-hooks/exhaustive-deps

  // const getPresalesCountHandler = async (contractAddress) => {
  //   try {
  //     const contract = getContract(InfoAbi, contractAddress);
  //     await contract.methods
  //       .getPresalesCount()
  //       .call()
  //       .then(async (receipt) => {
  //         setIsUpdating(true);
  //         console.log("getPresalesCount", receipt);
  //         for (let i = receipt - 1; i >= 0; i--) {
  //           await getPresaleAddressHandler(contractAddress, i);
  //         }
  //         setIsUpdating(false);
  //         console.log("I am done");
  //       });
  //   } catch (error) {
  //     console.error("error", error);
  //   }
  // };

  const getPresaleAddressHandler = async (contractAddress, i) => {
    try {
      const contract = getContract(DscvrInfoABI, contractAddress);
      await contract.methods
        .getPresaleAddress(i)
        .call()
        .then(async (receipt) => {
          await getPoolDataHandler(receipt, i);
        });
    } catch (error) {
      console.error("error***", error);
    }
  };

  const getPoolDataHandler = async (contractAddress, i) => {
    try {
      const calls = [
        // Balance of token in the LP contract
        {
          address: contractAddress,
          name: "closeTime",
        },
        {
          address: contractAddress,
          name: "totalInvestorsCount",
        },
        {
          address: contractAddress,
          name: "totalCollectedWei",
        },
        {
          address: contractAddress,
          name: "presaleCancelled",
        },
        {
          address: contractAddress,
          name: "ammLiquidityAdded",
        },
        {
          address: contractAddress,
          name: "hardCapInWei",
        },
        {
          address: contractAddress,
          name: "softCapInWei",
        },
        {
          address: contractAddress,
          name: "openTime",
        },
        {
          address: contractAddress,
          name: "ammLiquidityPercentageAllocation",
        },
        {
          address: contractAddress,
          name: "saleTitle",
        },
      ];

      const [
        closeTime,
        totalInvestorsCount,
        totalCollectedWei,
        presaleCancelled,
        uniLiquidityAdded,
        hardCapInWei,
        softCapInWei,
        openTime,
        uniLiquidityPercentageAllocation,
        saleTitle,
      ] = await multicall(DscvrPresaleABI, calls);
      let obj = {
        closeTime: JSON.parse(closeTime),
        totalInvestorsCount: totalInvestorsCount.toString(),
        totalCollectedWei: ethers.utils.formatEther(
          totalCollectedWei.toString()
        ),
        presaleCancelled: JSON.parse(presaleCancelled),
        uniLiquidityAdded: JSON.parse(uniLiquidityAdded),
        hardCapInWei: ethers.utils.formatEther(hardCapInWei.toString()),
        softCapInWei: ethers.utils.formatEther(softCapInWei.toString()),
        openTime: JSON.parse(openTime),
        uniLiquidityPercentageAllocation:
          uniLiquidityPercentageAllocation.toString(),
        saleTitle: ethers.utils.parseBytes32String(saleTitle.toString()),
        presaleAddress: contractAddress,
      };
      await updateState(i, obj);
    } catch (error) {
      console.error("error", error);
    }
  };

  const updateState = async (i, resultObj) => {
    setpoolListData((data) => [...data, { id: i, data: resultObj }]);
  };

  let data = {
    isLogin,
    userData,
    poolListData,
    network,
    isUpdating,
    networkId,
    upcommingPoolList,
    featuredPoolList,
    networkChnage: (data) => {
      window.localStorage.setItem("network", data);
      setNetwork(data);
      // history.push('/');
      // window.location.reload();
    },
    connectToWallet: (data) => connectToWallet(data),
    disconnectToWallet: disconnectToWallet,
  };
  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
