import { ethers } from 'ethers';
import Web3 from 'web3';

const RPC_URL_ETH =
  'https://kovan.infura.io/v3/2731597d41324dfbba606b6bca52f636';
const RPC_URL_BSC = 'https://data-seed-prebsc-1-s1.binance.org:8545/';
const RPC_URL_MATIC = 'https://rpc-mainnet.maticvigil.com/';

const getURL = () => {
  const network = window.localStorage.getItem('network');
  const url =
    network === 'Binance Chain'
      ? RPC_URL_BSC
      : network === 'Matic'
      ? RPC_URL_MATIC
      : RPC_URL_ETH;
  return url;
};

const httpProvider = new Web3.providers.HttpProvider(getURL());

/**
 * Provides a web3 instance using our own private provider httpProver
 */
const getWeb3 = () => {
  const web3 = new Web3(httpProvider);
  return web3;
};
const getContract = (abi, address, contractOptions) => {
  const web3 = getWeb3();
  return new web3.eth.Contract(abi, address, contractOptions);
};

export { getWeb3, getContract, httpProvider };

export function checkMetaMask() {
  let isMetaMask = false;
  if (window.ethereum) {
    isMetaMask = true;
  }
  return isMetaMask;
}

export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
  return sortAdd;
}

export async function getProvider() {
  const provider = await new ethers.providers.Web3Provider(window.ethereum);
  return provider;
}

export async function connectToWallet() {
  // await window.ethereum.request({ method: 'eth_requestAccounts' });
  // const provider = await getProvider();
  // const signer = await provider.getSigner();
  // let myAddress = await signer.getAddress();
  // window.localStorage.setItem('account', myAddress);
  // return 'connected';
}

export function accountsChanged(accounts) {
  let currentAccount = null;
  if (accounts.length === 0) {
    // MetaMask is locked or the user has not connected any accounts
    console.log('Please connect to MetaMask.');
    window.localStorage.removeItem('account');
  } else if (accounts[0] !== currentAccount) {
    currentAccount = accounts[0];
    window.localStorage.setItem('account', currentAccount);
    // Do any other work!
  }
  return currentAccount;
}

export const ethEnabled = async () => {
  try {
    if (window.ethereum) {
      console.log('window');
      await window.ethereum.enable();
    } else {
      // toast.error(CustomToastWithLink())
    }
  } catch (error) {
    console.log(error);
  }
};

export const getweb3Obj = async () => {
  let walletName = sessionStorage.getItem('walletName');
  console.log('walletName', walletName);
  if (walletName) {
    if (walletName === 'METAMASK') {
      return (window.web3 = new Web3(window.ethereum));
    } else if (walletName === 'BINANCE') {
      return (window.web3 = new Web3(window.BinanceChain));
    } else {
      return (window.web3 = new Web3(window.ethereum));
    }
  }
};
